const hosts = {
  localhost: "http://localhost:8080",
  default: "http://apidev-env.eba-mwsmpnji.us-east-1.elasticbeanstalk.com",
  dev: "http://apidev-env.eba-mwsmpnji.us-east-1.elasticbeanstalk.com",
  production: "http://vendeeapiprod.us-east-1.elasticbeanstalk.com",
};

const envAlias = process.env.REACT_APP_ENV_ALIAS || "default";
const fallback = hosts[envAlias];
//console.log(process.env.REACT_APP_ENV_ALIAS);
//console.log('URL fallback:', fallback);

// TODO Renomear para serverUrl
// export default {
//   serverUrl: process.env.REACT_APP_SERVER_URL || fallback,
// }
export const url = process.env.REACT_APP_SERVER_URL || fallback;
console.info(`Host URL: ${url}`);
